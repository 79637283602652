import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

var subscription = null;
var username = null;
var entries = null;
let sliceEntries = [];
let timeout = '';

// Connects to data-controller="wheel"
export default class extends Controller {

  // Setup the wheel stuff, only when data-controller is set to wheel
  connect() {
    if (subscription) {
      return;
    }
    console.log("Setting up Wheel");

    subscription = createConsumer().subscriptions.create({channel: "WheelChannel", twitch_id: document.querySelector('meta[name="twitch_id"]').content}, {

      connected: function() {
        console.log("Connected for Wheel");
      },
      received: function(data) {
        username = data.username;
        entries = data.entries;

        $("h1.username .user").text(username);

        // Load and spin the wheel
        loadWheel();

        $(".wheel").show()
      },
      disconnected: function() {
        console.log("Lost connection by disconnect!");
      },
    });
  }
}

// Shows who won the game & sends an API request
function wheelWinner(indicatedSegment)
{
  $("h1.winner").text(indicatedSegment.full_name);
  $("h1.donated").text(indicatedSegment.donated);

  // Send the won game/item ID over websocket with the username
  if (indicatedSegment.kind == "game") {
    subscription.perform("won", { game_id: indicatedSegment.id, username: username });
  } else if (indicatedSegment.kind == "item") {
    subscription.perform("won", { item_id: indicatedSegment.id, username: username });
  }

  // Hide the wheel after 8 seconds
  timeout = setTimeout(function() {
   $(".wheel").hide()
  }, 8000);
}


// Play the wheel sound
function playSound(){
  var wheelSound = new Audio('https://files.deanpcmad.com/florencebot/wheel.ogg');
  wheelSound.currentTime = 0;
  wheelSound.play();
}


function loadWheel() {
  // Cancel the timeout otherwise it will continue and hide during another spin
  clearTimeout(timeout)

  // Empty the array
  sliceEntries = [];

  // Create new wheel object specifying the parameters at creation time.
  var theWheel = new Winwheel({
    'outerRadius'     : 212,
    'innerRadius'     : 75,
    'textFontSize'    : 13,
    'textAlignment'   : 'outer',
    'numSegments'     : null,

    'animation' :
    {
      'type'     : 'spinToStop',
      'duration' : 8, // Duration in seconds.
      'spins'    : 3, // Default number of complete spins.
      'callbackSound'    : playSound, // play the wheel spin sound
      'callbackFinished' : wheelWinner
    }
  });

  // Add the games & items to the wheel
  for (var i = 0; i < entries.length; ++i) {
    // Define segments including colour and text.
    theWheel.addSegment(entries[i]);
  }

  // Loads places and segments to wheel
  theWheel.draw();

  // Vars used by the code in this page to do power controls.
  var wheelPower = 0;
  var wheelSpinning = false;


  // -------------------------------------------------------
  // Function for spin
  // -------------------------------------------------------
  function startSpin() {
    // Ensure that spinning can't be clicked again while already running.
    if (wheelSpinning == false) {
      // Begin the spin animation by calling startAnimation on the wheel object.
      theWheel.startAnimation();

      // Set to true so that power can't be changed and spin button re-enabled during
      // the current animation. The user will have to reset before spinning again.
      wheelSpinning = true;
    }
  }

  // -------------------------------------------------------
  // Function for reset
  // -------------------------------------------------------
  function resetWheel() {
    theWheel.stopAnimation(false);
    theWheel.rotationAngle = 0;
    theWheel.draw();
    wheelSpinning = false;
    $("h1.winner").text("...");
    $("h1.donated").text("");
  }

  // reset and spin the wheel at the end
  resetWheel();
  startSpin();
}
